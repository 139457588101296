import React, { Component } from 'react'
import { LocarcaseintrosecStyles } from './Locarcaseintrosec.styles'
import { images } from 'config/images'

class Locarcaseintrosec extends Component {
  render() {
    const {
      locarIntroData: { locarIntroContent },
    } = this.props

    return (
      <LocarcaseintrosecStyles>
        <div className="introSecRow">
          <div className="container">
            <h2>{locarIntroContent.maintile}</h2>
            <p>{locarIntroContent.mainpara}</p>
          </div>
        </div>
        <div className="carAnimationRow">
          <div className="road">
            <div className="carImage">
              <span>
                <img src={images.locarTexiImage} />
                <img className="carWheel" src={images.carWheel} />
                <img className="carWheel left" src={images.carWheel} />
              </span>
            </div>
          </div>
        </div>
      </LocarcaseintrosecStyles>
    )
  }
}

export default Locarcaseintrosec
