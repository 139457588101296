import styled from 'styled-components'
import { xl, lg, md, bodyFont } from 'config/variables'
import { images } from 'config/images'

export const LocarbookridevideosecStyles = styled.div`
  position: relative;
  padding-bottom: 80px;
  margin-bottom: 120px;
  ${xl(`
    margin-bottom: 90px;
  `)} 
  ${lg(` 
    padding-bottom: 60px; 
    margin-bottom: 60px; 
  `)}
  ${md(` 
    padding-bottom: 40px;
    margin-bottom: 40px;
  `)}
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 790px;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f8f9ff);
    z-index: -1;
  }
  .videoSecRow {
    margin-bottom: 75px;
    position: relative;
    background: #1e329e;
    padding: 72px 0 0;
    ${lg(`
        padding:52px 0 0; 
        margin-bottom:50px;
    `)}
    ${md(`
        padding:20px 0 0; 
        margin-bottom:30px;
    `)}
    .videoSecRowInner {
      .videoPlayer {
        display: flex;
        justify-content: center;
        max-width: 948px;
        margin: 0 auto;
        video {
          max-width: 100%;
        }
      }
    }
  }
  .LocarAppScreenRow {
    .appScrennMainRow {
      display: flex;
      justify-content: space-between;
      .leftContentpart {
        max-width: 200px;
        flex: 0 0 200px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        ${lg(`
          max-width:139px;
          flex:0 0 139px;
        `)}
        ${md(`
          display:none;
        `)}
        .bottom-content {
          position: relative;
          padding-top: 21px;
          &:before {
            content: '';
            position: absolute;
            width: 55px;
            height: 3px;
            background: #3d3d3d;
            top: 0;
            left: 0;
          }
          h4 {
            font-size: 15px;
            font-weight: 600;
            font-family: ${bodyFont};
            margin-bottom: 6px;
            text-transform: uppercase;
            line-height: 21px;
          }
          p {
            font-size: 15px;
            letter-spacing: -0.16px;
            line-height: 21px;
            margin: 0;
          }
        }
      }
      .LocarAppScreenRight {
        flex: 1 1 auto;
        width: 100%;
        padding-left: 10px;
        ${md(`
          padding-left: 0;
          overflow: auto;
        `)}
        .LocarAppScreenInnerRow {
          display: flex;
          justify-content: space-between;
          ${md(`
            width:445px;
            margin:0 auto;
          `)}
          .screenList {
            flex: 0 1 50%;
            .appScreenItem {
              position: relative;
              z-index: 1;
              max-width: 230px;
              margin: 0 0 76px;
              ${lg(`
                  max-width: 170px;
                  margin: 0 0 50px;
              `)}
              ${md(`
                  max-width: 140px;
                  margin: 0 auto 30px;
              `)}
              &::after {
                content: '';
                position: absolute;
                height: 175px;
                width: 154px;
                bottom: -130px;
                right: -96px;
                z-index: -1;
                background: url(${images.locarScreenIntroRow}) no-repeat;
                ${lg(`
                   background-size: 90px;
                   height: 125px;
                   width: 94px;
                   bottom: -92px;
                   right:-53px;
                `)}
                ${md(`
                   background-size: 65px;
                  height: 96px;
                  width: 74px;
                  bottom: -62px;
                  right: -43px;
                `)}
              }
              &:nth-child(even) {
                &::after {
                  right: inherit;
                  left: -104px;
                  transform: scaleX(-1);
                  ${lg(`
                   left: -59px;
                `)}
                  ${md(`
                   left: -48px;
                `)}
                }
              }
              &:first-child {
                &::after {
                  display: none;
                }
              }
              &:last-child {
                margin-bottom: 0;
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
`
