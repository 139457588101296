import React from 'react'

import { LocarbookridevideosecStyles } from './Locarbookridevideosec.styles'
import Videoplayer from 'components/Videoplayer/Videoplayer'
import Image from 'components/ImgOptimized'

function Locarbookridevideosec(props) {
  const {
    locarBookRidevideoData: { locarBookRidevideoProps },
  } = props

  return (
    <LocarbookridevideosecStyles>
      <div className="videoSecRow">
        <div className="container">
          <div className="videoSecRowInner">
            <Videoplayer videoURL={locarBookRidevideoProps.videoURL} />
          </div>
        </div>
      </div>
      <div className="LocarAppScreenRow">
        <div className="container">
          <div className="appScrennMainRow">
            <div className="leftContentpart">
              <div className="bottom-content">
                <h4>{locarBookRidevideoProps.bottomTitle}</h4>
                <p>{locarBookRidevideoProps.bottomContent}</p>
              </div>
            </div>
            <div className="LocarAppScreenRight">
              <div className="LocarAppScreenInnerRow">
                <div className="screenList">
                  {props.leftScreenImg.map((leftScreenImgItem, i) => {
                    return (
                      <div key={i} className="appScreenItem">
                        <Image
                          fluid={leftScreenImgItem}
                          lazyload={{
                            enabled: true,
                            once: true,
                            offset: 1000,
                            heigth: 411,
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
                <div className="screenList">
                  {props.rightScreenImg.map((rightScreenImgItem, i) => {
                    return (
                      <div key={i} className="appScreenItem">
                        <Image
                          fluid={rightScreenImgItem}
                          lazyload={{
                            enabled: true,
                            once: true,
                            offset: 1000,
                            heigth: 411,
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LocarbookridevideosecStyles>
  )
}

export default Locarbookridevideosec
