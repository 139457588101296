import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { locarProps } from 'components/Props/locar'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { xl, lg, md, primaryColor, sm } from 'config/variables'
import Locaranimationbanner from 'components/Locaranimationbanner/Locaranimationbanner'
import Locarcaseintrosec from 'components/Locarcaseintrosec/Locarcaseintrosec'
import Locarridebooksec from 'components/Locarridebooksec/Locarridebooksec'
import Engagementsec from 'components/Engagementsec/Engagementsec'
import Locarbookridevideosec from 'components/Locarbookridevideosec/Locarbookridevideosec'
import CasePowerTools from 'components/CasePowerTools/CasePowerTools'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Fleetarchitecture from 'components/Fleetarchitecture/Fleetarchitecture'
import Challangesgoalsec from 'components/Challangesgoalsec/Challangesgoalsec'
import Customerexperiencesec from 'components/Customerexperiencesec/Customerexperiencesec'
import SmallImgContentblk from 'components/SmallImgContentblk/SmallImgContentblk'
import Paymentgatewaysec from 'components/Paymentgatewaysec/Paymentgatewaysec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import Caseresultsec from 'components/Caseresultsec/Caseresultsec'

export const Locarpage = styled.div`
  .caseAnimationBanner {
    .img-block { 
      .bannerImage {
        width: 700px;
        position: relative;
        height:580px;
        ${xl(`
          width: 460px;
          height:380px;
        `)}
        ${lg(`
          width: 370px;
          height:300px;
        `)}
        ${sm(`
         width: 280px;
         height: 238px;
        `)}
        img {
          position:absolute;
          &.circleImg {
            right:10px; 
            top:0;
            z-index:1;
            ${xl(`
              max-width:367px;
            `)}
            ${lg(`
              max-width:290px;
            `)}
            ${sm(`
              max-width:230px;
              right:0;
            `)}
          }
          &.mobileScreenImg {
            left: 0px;
            bottom: 6px;
            z-index: 9;
            max-width: 485px;
            ${xl(`
              max-width:315px;
            `)}
            ${lg(`
              max-width:250px;
            `)}
            ${sm(`
              max-width:200px;
            `)}
          }
          &.languageBox {
            top:93px;
            left:10px;
            z-index:2;
            transform: translatex(0px) scale(0.3);
	          animation: floatx 8s ease-in-out infinite;
            ${xl(`
              max-width: 190px;
              top: 52px;
              left: 0;
            `)}
            ${lg(`
              max-width: 160px;
              top: 42px;
              left: 0;
            `)}
            ${sm(`
              max-width: 120px;
              top: 30px;
              left: 0;
            `)}
          }
          &.noInternet {
            top: 203px;
            right: 63px;
            z-index: 2;
            transform: translatey(0px);
	          animation: float 10s ease-in-out infinite;
            animation-delay: 4s;
            ${xl(`
              max-width: 120px;
              top: 143px;
              right: 63px;
            `)}
            ${lg(`
              max-width: 90px;
              top: 115px;
              right: 53px;
              animation-delay: 3s;
            `)}
            ${sm(`
              max-width: 50px;
              top: 100px;
              right: 49px;
            `)}
          }
          &.refreshBtn {
            top: 186px;
            left: 160px;
            z-index: 9;
            animation: rotation 15s infinite linear;
            transform-origin:center;
            ${xl(`
              max-width: 45px;
              top: 119px;
              left: 107px;
            `)}
            ${lg(`
              max-width: 35px;
              top: 95px;
              left: 87px; 
            `)}
            ${sm(`
              max-width: 30px;
              top: 74px;
              left: 67px; 
            `)}
          }
          &.updateBox {
            top: 87px;
            right: -29px;
            z-index: 9;
            transform: translatey(0px);
	          animation: float 10s ease-in-out infinite;
            ${xl(`
              top: 65px;
              right: -20px;
              max-width: 210px;
            `)}
            ${lg(`
              top: 50px;
              right: -17px;
              max-width: 165px;
            `)}
            ${sm(`
              top: 45px;
              right: -15px;
              max-width: 120px;
            `)}
          }
        }
      }
    }
  }
  .engagementSec {
    margin: 0px;
    .casequote-section {
      margin-bottom: 120px;
      ${xl(`   
        margin-bottom: 100px; 
      `)}
      ${lg(`
        margin-bottom: 60px;
      `)}
      ${md(`
        margin-bottom: 40px; 
      `)} 
    }
  }
  .Caseresultsec  {
    padding-bottom:0px;
    margin-bottom: 120px;
    ${xl(`   
      margin-bottom: 100px; 
    `)}
    ${lg(`
      margin-bottom: 60px;
    `)}
    ${md(`
      margin-bottom: 40px; 
    `)} 
    &::after{
      display:none;
    }
    .video-section {
      text-align:center;
    } 
  }
  
  .casepower-section {
    margin-bottom: 120px;
    ${xl(`   
      margin-bottom: 100px; 
    `)}
    ${lg(`
      margin-bottom: 60px;
    `)}
    ${md(`
      margin-bottom: 40px; 
    `)} 
    .powerContentRow {
      padding-bottom: 20px;
      &:last-child {
        padding-bottom:0px;
      }
    }
  }
  .caseintro-section {
    padding-bottom: 42px;
    position: relative;
    margin-bottom: 120px;
    p {
      margin-bottom:0px;
    }
    ${xl(`
        margin-bottom: 100px;
    `)}
    ${lg(`
      margin-bottom: 60px;
      padding-bottom: 40px;
    `)}
    ${md(`
        margin-bottom: 40px;
        padding-bottom: 20px;
    `)}  
    &:after {
      position: absolute;
      content: '';
      background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f8f9ff);
      width: 100%;
      height: 676px;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
  .fleetarchitectureSec {
    padding-bottom:0px; 
    margin-bottom: 120px;
    ${xl(`   
      margin-bottom: 100px; 
    `)}
    ${lg(`
      margin-bottom: 60px;
    `)}
    ${md(`
      margin-bottom: 40px; 
    `)} 
    &::after {
      display:none;
    }
     .fleetArchitectureRow {
        .architectureList {
          li {
            color:#000;
          }
        }
    }
  } 
  .challangesgoalsec  {
    padding-bottom: 20px;
    position: relative;
    margin-bottom: 120px; 
    ${xl(`   
      margin-bottom: 100px; 
    `)}
    ${lg(`
      margin-bottom: 60px; 
    `)}
    ${md(`
      margin-bottom: 40px; 
    `)}  
    &:after {
      position: absolute;
      content: '';
      background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f8f9ff);
      width: 100%;
      height: 676px;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
    .row { 
      .left-part {
        flex: 0 1 50%;
        padding-right:30px;
        ${xl(`
          padding-right:20px;
        `)} 
        ${lg(`
            padding-right:15px;
        `)} 
        ${md(`
          flex: 0 0 auto;
        `)} 
      }
      .right-part {
        flex: 0 1 50%;
        padding-left:30px;
        ${xl(`  
            padding-left:20px;
        `)} 
        ${lg(`  
           padding-left:15px;
        `)} 
        ${md(`
          flex-direction: column;
          padding-left:0px;
        `)} 
      }
    }
  }
  .customerExperienceSec  { 
    margin-bottom: 120px; 
    ${xl(`
        margin-bottom: 90px;
    `)}
    ${lg(`
      margin-bottom: 60px; 
    `)}
    ${md(`
        margin-bottom: 40px; 
    `)} 
    .CustomerExpMainTitle  {
        h2 {
          margin-bottom:0px; 
        }
      }
  }
  .smallImgContentblk {
    padding-bottom: 52px;
    position: relative;
    margin-bottom: 95px; 
    ${lg(`
      margin-bottom: 60px; 
    `)}
    ${md(`
        margin-bottom: 40px; 
    `)}  
    &:after {
      position: absolute;
      content: '';
      background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f8f9ff);
      width: 100%;
      height: 676px;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
    .sectionTitle {
      h2 {
        padding-bottom: 30px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0px;
          width: 251px;
          height: 3px;
          background: ${primaryColor};
          ${md(`
            width: 90px;
          `)}
        }
      }
    } 
    .row {
      margin-top:50px;
      ${lg(`
        margin-top:30px;
      `)}
      .imgCol {
        ${lg(`
            margin:0 auto 30px;
          `)}
        figure {
          ${lg(`
            margin:0px;
          `)}
        }
      }
    }
  }
  .locarSupportSec {
    .customerExperienceSec  {
      .CustomerExpMainTitle  {
        h2 {
          padding-bottom:0px;
          margin-bottom:20px;
          &::after {
            display:none;
          }
        }
      }
    }
  }
 
`
const GoCabs = props => {
  const { data } = props
  const { location } = props

  let appDriverScreen = [
    props.data.locardriverscreen1.childImageSharp.fluid,
    props.data.locardriverscreen2.childImageSharp.fluid,
    props.data.locardriverscreen3.childImageSharp.fluid,
    props.data.locardriverscreen4.childImageSharp.fluid,
  ]
  let passengerScreen = [
    props.data.locarpassengerscreen1.childImageSharp.fluid,
    props.data.locarpassengerscreen2.childImageSharp.fluid,
    props.data.locarpassengerscreen3.childImageSharp.fluid,
    props.data.locarpassengerscreen4.childImageSharp.fluid,
  ]

  let itembottom = [
    props.data.fleetAndBackOffice.childImageSharp.fluid,
    props.data.chargingRequests.childImageSharp.fluid,
  ]
  let locarAlgorithmDiagram = [
    props.data.locarAlgorithmDiagram.childImageSharp.fluid,
  ]
  let cusExpeItem = [props.data.improvingImg.childImageSharp.fluid]

  let cusSupportItem = [
    props.data.locarSystemImg.childImageSharp.fluid,
    props.data.locarCusSupportImg.childImageSharp.fluid,
    props.data.locarCusSupportImg.childImageSharp.fluid,
  ]
  let locarPaymnetImg = [props.data.locarPaymnetImg.childImageSharp.fluid]
  let locarMockupImg = [props.data.locarMockupImg.childImageSharp.fluid]

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="GoCabs"
        description=""
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <Locarpage>
        <Locaranimationbanner bannerdata={locarProps} />
        <Locarcaseintrosec locarIntroData={locarProps} />
        <div className="lazy-load-div">
          <Locarridebooksec bookRideData={locarProps} />
        </div>
        {showContent && (
          <>
            <Engagementsec
              engagementsecdata={locarProps}
              {...props}
              showFullimages={false}
            />
            <Caseresultsec
              caseresultsecdata={locarProps}
              {...props}
              showImages={false}
            />
            {/* <Locarbookridevideosec
              locarBookRidevideoData={locarProps}
              leftScreenImg={passengerScreen}
              rightScreenImg={appDriverScreen}
            /> */}
            <CasePowerTools
              CasePowerToolsdata={locarProps.CasePowerToolsSecProps}
              items={itembottom}
            />
            <Caseintroduction
              caseintrodata={locarProps}
              {...props}
              showFullimages={true}
            />
            <Fleetarchitecture
              Fleetarchitecturedata={locarProps}
              {...props}
              showFullimages
            />
            <Challangesgoalsec
              challangesgoaldata={locarProps}
              imageItems={locarAlgorithmDiagram}
              {...props}
            />
            <Customerexperiencesec
              CustomerExperienceProps={locarProps.customerExperienceProps}
              {...props}
              fluid={cusExpeItem}
            />
            <SmallImgContentblk
              smallImgcontentdata={locarProps}
              {...props}
              showFullimages={true}
            />
            <div className="locarSupportSec">
              <Customerexperiencesec
                CustomerExperienceProps={locarProps.locarSupportSecProps}
                {...props}
                fluid={cusSupportItem}
              />
            </div>
            <Paymentgatewaysec
              paymentGatewayData={locarProps}
              paymnetImg={locarPaymnetImg}
              mockupImg={locarMockupImg}
              {...props}
            />
            <Relatedcaselist relatedcaselistdata={locarProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={locarProps} />
          </>
        )}
      </Locarpage>
    </Layout>
  )
}

export default GoCabs

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/gocabs-banner-image@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fihmainscreen: file(relativePath: { regex: "/fihmainscreen@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1166) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen1: file(relativePath: { regex: "/swifts1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen2: file(relativePath: { regex: "/swifts2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen3: file(relativePath: { regex: "/swifts3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen4: file(relativePath: { regex: "/swifts4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen5: file(relativePath: { regex: "/swifts5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen6: file(relativePath: { regex: "/swifts6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen7: file(relativePath: { regex: "/swifts7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen8: file(relativePath: { regex: "/swifts8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen9: file(relativePath: { regex: "/swifts9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseFullimages: file(relativePath: { regex: "/insuranceDetails@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 866) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locardriverscreen1: file(
      relativePath: { regex: "/gocabsdriverscreen-one@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locardriverscreen2: file(
      relativePath: { regex: "/gocabsdriverscreens-two@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locardriverscreen3: file(
      relativePath: { regex: "/gocabsdriverscreens-three@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locardriverscreen4: file(
      relativePath: { regex: "/gocabsdriverscreen-four@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarpassengerscreen1: file(
      relativePath: { regex: "/gocabspassengercreens-one@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarpassengerscreen2: file(
      relativePath: { regex: "/gocabspassengerscreen-two@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarpassengerscreen3: file(
      relativePath: { regex: "/gocabspassengerscreen-three@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarpassengerscreen4: file(
      relativePath: { regex: "/gocabspassengerscreen-four@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 243) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fleetAndBackOffice: file(
      relativePath: { regex: "/locar-cabbook-dashboard@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 575) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    chargingRequests: file(
      relativePath: { regex: "/gocabs-cabbook-experience@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 487) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/locar-native-app-image@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ArchitectureImages: file(
      relativePath: { regex: "/locar-user-expe-image@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1009) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarAlgorithmDiagram: file(
      relativePath: { regex: "/gocabs-algorithm-diagram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    improvingImg: file(
      relativePath: { regex: "/gocabs-offline-bookings@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    scalefusion: file(relativePath: { regex: "/locar-tmap-image@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 598) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarSystemImg: file(
      relativePath: { regex: "/locar-systems-image@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 384) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarCusSupportImg: file(
      relativePath: { regex: "/gocabs-cus-supports@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 492) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarPaymnetImg: file(
      relativePath: { regex: "/gocabs-payment-bg-image@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 599) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarMockupImg: file(
      relativePath: { regex: "/locar-pay-mockup-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 428) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/fts-casestudies-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/craypay-casestudies-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
