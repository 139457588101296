import styled from 'styled-components'
import { md, lg, xl } from 'config/variables'
import { images } from 'config/images'

export const LocarcaseintrosecStyles = styled.div`
  padding:62px 0 0; 
  position: relative;
  margin-bottom: 120px; 
  overflow:hidden;
  ${xl(`   
      margin-bottom: 100px;
      padding:62px 0 0;
  `)}
  ${lg(`
  margin-bottom: 100px;
      padding:50px 0 0;
  `)}
  ${md(`
  margin-bottom: 40px;
      padding:20px 0 0;
  `)}
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), #5ac3ff);
    z-index: -1;
    opacity: 0.2;
  }
  .introSecRow{
    padding-bottom:82px;
    ${xl(`   
        padding-bottom:60px;
    `)}
    ${lg(`
        padding-bottom:50px;
    `)}
    ${md(`
        padding-bottom:40px;
    `)}
  }
  .carAnimationRow {
    position:relative;
    height:95px;
    display:flex;
    align-items:flex-end;
    .road {
      background:url(${images.locarRoad});
      height:51px;
      width:100%;
      .carImage {
        position:absolute;
        top:0px;
        left:-100%;
        height:74px;
        width:206px;
        animation: carDrive 8s linear infinite;
        ${md(`
            animation: carDrive 3s linear infinite;
        `)}
        span {  
          position:relative;
          .carWheel {
            position:absolute; 
            bottom: 3.5px;
            right: 19px;
            animation: carWheelRotation 0.5s infinite linear;
            transform-origin:center;
            background:#000;
            border-radius:50%;
            height: 32px;
            width: 32px;
            &.left {
              right:inherit;
              left:29px;
            }
            ${md(`
                animation: carWheelRotation 0.8s infinite linear;
            `)}
          }
        }
      }
    }
  }
  @keyframes carDrive {
  0% { left: -100%; }
  100% { left: 100%; }
  } 
  @keyframes carWheelRotation{
    100% {
        transform: rotate(360deg);
    }
  }
`
