import React from 'react'
import { images } from 'config/images'

export const locarProps = {
  locarBannerProps: {
    heading: 'GoCabs',
    bannerPara: 'brings Mobility through PWA to 50+ million passengers',
    BannerAlt: 'GoCabs Case Study',
    locarBannerImage: [
      {
        className: 'circleImg',
        imgUrl: images.locarbannerCircle,
      },
      {
        className: 'mobileScreenImg',
        imgUrl: images.locarmobileScreen,
      },
      {
        className: 'languageBox',
        imgUrl: images.locarlanguageBox,
      },
      {
        className: 'noInternet',
        imgUrl: images.locarNoInternet,
      },
      {
        className: 'refreshBtn',
        imgUrl: images.locarRefresh,
      },
      {
        className: 'updateBox',
        imgUrl: images.locarupdateBox,
      },
    ],
  },
  locarIntroContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        A good public transport system is an indicator of a healthy economy.
        However, public transport cannot reach you whenever and wherever you
        want. Hence in most developed Countries, cabs serve as an alternative to
        the lacklustre transportation system. <br />
        <br />
        In Korea, even with the rising demand of cab hailing service, the
        struggle for a reliable on demand cab service continues. Hence, by
        turning to a Progressive Web App (PWA). GoCabs is aiming for the
        ultimate goal of bringing easy mobility to 50+ million users in Korea.
      </>
    ),
  },
  bookRideContent: {
    maintile:
      'Meeting demand during peak hours and leveling unjust fare charges',
    paraLeft:
      'Ride hailing market in Korea is dominated by giants like Kakao and T Map. However, even with a good network of drivers, they are not able to meet the demand during peak hours. Especially in tier 2 and tier 3 cities.',
    paraRight:
      'Also, more than 13 million tourists visit Seoul each year. Surprisingly, native cab booking apps in Korea don’t respond to foreigners in the language they speak. Making tourists fall prey to fleecing cab service providers and extremely poor travel experience. Hence, to address these challenges and more. GoCabs needed a tech solution which can meet demand at all hours and under any circumstances.',
    fadeInsecProps: {
      animationImageBlock: [
        {
          imgUrl2x: images.bookRideImgone2x,
          imgUrl: images.bookRideImgone,
        },
        {
          imgUrl2x: images.bookRideImgtwo2x,
          imgUrl: images.bookRideImgtwo,
        },
        {
          imgUrl2x: images.bookRideImgthree2x,
          imgUrl: images.bookRideImgthree,
        },
      ],
    },
  },
  EngagementsecProps: {
    Title: 'Engagement',
    subTitle: (
      <>Integrating with GoCabs to build a faster and scalable solution</>
    ),
    para: (
      <>
        Since the founders were Non-Technical. We integrated with GoCabs by
        providing CTO (Chief Technical Officer) as an offering. CTOs gave
        architectural consultation to the founders by analysing the requirement
        and business to develop an innovative tech solution.
        <br />
        <br />
        GoCabs needed a team which can instantly take them to market for quick
        user feedback. Hence, we shifted gears to an agile and lean approach,
        which came with our Dedicated Team’s Model. With the help of our CTOs,
        they hired backend and frontend Node.js and react developers, who can
        scale up and down as the need grows.
      </>
    ),
    caseQuoteProps: {
      maintext: (
        <>
          <span>“Simform have gone above and beyond our expectations</span>{' '}
          integrating seamlessly with our own team to deliver cutting edge
          technology in a timely and professional manner.”
        </>
      ),
      // userImg: images.edna,
      // userImg2x: images.edna2x,
      // userName: 'Edna Schwartz',
      // designation: 'Chief Executive Officer',
    },
  },
  casereSultsecProps: {
    heading: 'Result',
    maincontent: (
      <>
        Simform successfully developed a{' '}
        <strong>Progressive Web App (PWA)</strong> for Rider module using
        React.JS with highly responsive Node.JS server to provide a
        faster-loading, immersive and an app-like experience. We also delivered{' '}
        <strong>Driver’s app</strong> in native android. It offers riders an
        easy discovery and low friction process to book a cab instead of
        downloading an app. <br /> <br />
        Using just 150KB of data to install, PWA proved to be 200X times faster
        and 500X times smaller than the average Android and iOS cab booking apps
        respectively. Such low consumption of data provided a seamless
        experience with 2.6s time for the first visit and less than a second for
        subsequent visits on 3G & 4G networks across South Korea.
      </>
    ),
    imgURL: images.locarResultLeptopscreen,
    imgURL2x: images.locarResultLeptopscreen2x,
  },

  locarBookRidevideoProps: {
    videoURL: images.bookRideVideo,
    bottomTitle: 'Development',
    bottomContent: 'Web and Mobile apps for Passengers and Drivers',
  },
  CasePowerToolsSecProps: {
    mainTitle:
      'Setting the stage for a reliable on demand cab booking experience',
    CasePowerToolsDetails: [
      {
        para: (
          <>
            87.2 percent of South Koreans aged 13 years and above own a
            smartphone. While the omnipresence of smartphones and the demand for
            cabs pose huge potential for growth. An average user is repelled by
            native apps taking huge functional space.
          </>
        ),
      },
      {
        para: (
          <>
            Hence, to assume the ultimate goal of reaching 50+ million users.{' '}
            <strong>
              We decided opting for a Progressive Web App (PWA). A functional
              solution which responds with the same ease in remote areas, as it
              does in the most urban locations.
            </strong>
          </>
        ),
      },
    ],
  },
  introducrtionContent: {
    maintile: 'Providing Native app-like experience without a Native app',
    mainpara: (
      <>
        Turning to PWA turned GoCabs to deliver a lightweight, easily
        deployable, high speed cab booking app. Which performs like any other
        app. But without asking users to trade mobile storage, internet, or
        regular updates.
        <br />
        <br />
        Progressive Web App (PWA) are web apps which are faster, reliable and
        more engaging than native apps. They helped us deploy native app-like
        experience while solving other challenges like:
      </>
    ),
  },
  Fleetarchitectureprops: {
    heading:
      'From browser to the Home Screen. Improving User Experience with PWA',
    LeftBottomDetailsPara: (
      <>
        Providing features, available only to native applications, is what makes
        PWA a modern app for the modern world. Hence, to improve user
        experience, we made use of 4 key building blocks of PWA. Web App
        Manifest file, Service Workers, Application Shell Architecture and
        Transport Layer Security (TLS) <br /> <br />
        With Web App Manifest file we were able to achieve{' '}
        <strong>‘Add to Home Screen Prompt’</strong> on google chrome. Which
        allowed users to add the application on their home screen and use it
        like any native app. <br /> <br />
        Web App Manifest File did the work of informing the browser about our
        PWA and how it should behave when ‘installed’ on a user’s mobile. To add
        conversion hacking features in the app like push notifications, offline
        functionality etc. We added service workers to the browser.
      </>
    ),
    RightBottomDetailsPara:
      'Service workers with Application Shell Architecture worked independently from the main browser thread which helped us achieve the following features for GoCabs:',
    FleetarchitectureBottomList: [
      'Achieve high loading speed, even without internet.',
      'Add application data to the browser cache when online & provide that data when offline.',
      'Display push notifications to the user when website is not open.',
    ],
    RightBottomListPara: (
      <>
        Lastly, by using Transport Layer Security (TLS), we were able to provide
        a very <strong>secure data exchange</strong> between devices. Which
        simply safeguarded GoCabs over a secure connection.
      </>
    ),
  },
  challangesgoalProps: {
    heading: 'Implementing scheduling algorithm with Node.js',
    leftDetailsPara:
      'Currently GoCabs supports scheduled booking only. Also it has two types of rides: One Way and Hourly. An average user expects the arrival of the cab in the shortest possible time.',
    leftPartext:
      'GoCabs sends ride request asynchronously to all the drivers in close proximity. Interested drivers can accept or reject the ride.',
    rightPara: (
      <>
        To make the process agile, we kept a timeout of 1 min for drivers to
        accept or reject the ride.
        <br />
        <br />
        In case no driver accepts the ride, we had to flag them immediately and
        process the request on priority so that no request can go unattended.
        <br />
        <br />
        The entire process, due to its asynchronous nature, is built using
        Node.js. Where the single thread capability comes handy in processing
        all requests.
        <br />
        <br />
        To improve the quality of the service. The entire scheduling algorithm
        has been written in the form of a priority queue on Node.js. Which is
        designed to match the users with a specific set of parameters like
        driver availability, favourite driver selection to the closest driver.
      </>
    ),
  },
  customerExperienceProps: {
    CustomerexperienceDetails: [
      {
        mainTitle: 'Making GoCabs Offline First',
        para: (
          <>
            GoCabs wanted to increase its usage in Tier 2 and Tier 3 cities.
            Cities where smooth internet access at all hours is still a
            struggle. Also to serve passengers the convenience of{' '}
            <strong>booking cab when offline</strong> was a feature we felt was
            necessary for a modern app.
            <br />
            <br />
            To make an offline first app, we executed service workers to Fetch
            (retrieve content from the network) and Cache (store content for
            application data). The cache, because of service workers worked
            independent from the browser cache and network status. Making app
            work during network interruptions or no network.
          </>
        ),
      },
    ],
  },
  smallImgcontentProps: {
    heading: 'Navigation made possible with TMap and Geo encoding APIs',
    para:
      'In most Countries, cab booking apps find google maps best for navigation. However, not Korea. With restrictions on google maps, we needed a native solution to enable location tracking and directions for GoCabs’s PWA. Hence, we moved to TMap for navigating routes and directions.',
    bottomPara: (
      <>
        With the help of <strong>route guide api</strong>, we were able to show
        direction between the places, along with distance and time required to
        complete the trip .To convert manually entered addresses into geographic
        coordinates, we enabled <strong>Geo Encoding Api</strong>. Which helped
        the app to fetch the location of the selected place of interest.
        Destination, arrival points and stop overs.
      </>
    ),
  },

  locarSupportSecProps: {
    CustomerexperienceDetails: [
      {
        mainTitle:
          'Catering a variety of users with Multilingual Support System',
        para: (
          <>
            To build an app for different nationalities. We made use of
            React.intl and Provider component to enable changing of language
            during runtime.{' '}
            <strong>
              Using react.intl, we were able to add language specific parameters
              in the app.
            </strong>{' '}
            And provider component helped us obtain the languages and its values
            from across the Globe.
          </>
        ),
      },
      {
        mainTitle: 'Introducing a standard fare calculation practice',
        para: (
          <>
            Due to the Lack of standard practice, unjust fare charges became one
            of the main challenges passengers faced in Korea. Especially
            visiting foreigners from other Countries. They were charged
            unrealistic amounts for short and long distances. To solve problem,
            we upgraded GoCabs with Geofencing.
            <br />
            Geofencing uses GPS to put virtual boundaries around geographical
            areas and enable GoCabs to set fixed fare for areas with surging
            demand.{' '}
            <strong>
              Geofencing helped us set fixed fares for defined geographies
            </strong>{' '}
            and send notification when the driver crosses the defined zone.
          </>
        ),
        imageLink: images.locarMapAnimation,
      },
      {
        mainTitle: 'Developing a faster in-app customer support',
        para: (
          <>
            During our pilot study, riders were facing issues when it comes to
            sharing their complaints and feedback. ZenDesk solved the problem by
            providing riders with the in-app customer support. Which reduces the
            gap by directly putting users in touch with the app’s developers. It
            automatically collects the device’s information, which otherwise
            would require a lot of emails. It also has a mobile app for support
            teams which provides users with microblogging like website
            experience.
          </>
        ),
      },
    ],
  },
  paymentGatewayProps: {
    Title: 'Integrating multi-channel payment gateway',
    para: (
      <>
        Mobile payments account for more than 90 percent of the total payments
        in South Korea.
        <br />
        <br />
        To create a homogenous payment system, we integrated payment using
        Import Library to integrate WeChat Pay and other international payment
        options.
        <br />
        <br /> We customized this to split the fare, tipping, and revenue
        sharing with seamless backend logic.
      </>
    ),
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Food Truck Spaces',
        para:
          'Eases finding, booking and scheduling properties in the $2 Billion Food Industry',
        className: 'light-peach',
        link: 'case-studies/food-truck-spaces/',
      },
      {
        title: 'CrayPay',
        para: 'Making M-Payment a profitable payment option in retail stores',
        className: 'mint',
        link: 'case-studies/cray-pay/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
